<template>
    <div class="actions-buttons">
        <template v-if="isDraft">
            <delete-button v-tooltip.bottom="'Delete'" :memod="memod" @delete-memo="(memoId) => $emit('delete-memo', memoId)" />
            <router-link v-tooltip.bottom="'Edit'" :to="{ name: 'web-app-edit', params: { writer: `${memod.writer ? memod.writer.displayname : memod.user.displayname}`, slug: memod.slug, id: memod.id, partNumber: 1 } }" @click.native="toggleDraftsList">
                <img
                    src="@assets/img/icons/edit.svg"
                    class="edit"
                    alt="edit"
                >
            </router-link>
        </template>
        <template v-else>
            <like-button v-tooltip.bottom="'Like'" :memod="memod" @toggled-like="(data) => $emit('toggled-like', data)" />
            <share-button v-tooltip.bottom="'Share'" :memod="memod" :is-serialized="isSerialized" />
            <save-button v-tooltip.bottom="'Save to library '" :memod="memod" @toggled-save="(is_saved) => $emit('toggled-save', is_saved)" />
        </template>
    </div>
</template>

<script>
export default {
    name: "ActionsButtons",
    components: {
        DeleteButton: () => import(/* webpackChunkName: "delete-button" */ "@c/atoms/delete-button"),
        LikeButton: () => import(/* webpackChunkName: "like-button" */ "@c/atoms/like-button"),
        ShareButton: () => import(/* webpackChunkName: "share-button" */ "@c/atoms/share-button"),
        SaveButton: () => import(/* webpackChunkName: "save-button" */ "@c/atoms/save-button")
    },
    props: {
        memod: {
            type: Object,
            required: true
        },
        isDraft: {
            type: Boolean,
            default: false
        },
        isSerialized: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        toggleDraftsList() {
            this.$store.dispatch("Application/toggleDraftsList", false);
        }
    }
}
</script>

<style lang="scss">
.actions-buttons {
    margin-left: auto;
    display: flex;
    align-items: center;

    > div {
        margin-left: 30px;
        width: 33px;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
            margin-left: 0;
        }
    }

    * {
        height: 18px;
    }
}
</style>
